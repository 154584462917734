<template>
  <div class="newProduct">
    <div class="newProduct-list">
      <div class="newProduct-list-item" v-for="item in data.products" :key="item.id" @click="getNewProductDetial(item)">
        <div v-if="item.discount > 0" class="tag">
          <div class="top"><span>{{ item.discount }}</span>%</div>
          <div class="bottom">OFF</div>
        </div>
        <div class="newProduct-list-img">
          <img :src="getProductImageUrl(item.picturePath,400)" @error="handleImageError" />
        </div>
        <div class="newProduct-info">
          <div class="newProduct-name">
            {{ item.brandName ? item.brandName : "" }} {{ item.name }}
          </div>
          <div v-if="data.isAuth">
            <div v-if="parseInt(item.price) !== 1000000"
              style="display: flex;align-items: flex-start;justify-content: space-between;margin-top: 4px;">
              <div>
                <div class="newProduct-price" v-if="
                  parseInt(item.price) !== 1000000 &&
                  (item.price > 0 || item.discountPrice > 0)
                ">
                  {{
                    formatNumber(
                      item.price !== item.discountPrice && item.discountPrice > 0
                        ? item.discountPrice
                  : item.price
                  )
                  }}
                </div>
                <div class="newProduct-Originalprice" v-if="
                  item.price !== item.discountPrice &&
                  parseInt(item.price) !== 1000000 && item.discountPrice > 0
                ">
                  {{ formatNumber(item.price) }}
                </div>
              </div>
              <div class="add" @click.stop="handleBuyProduct(item)">
                <img src="@/assets/image/search/add.png">
              </div>
            </div>
            <div class="newProduct-price" v-else>
              {{ $t("詢價") }}
            </div>
          </div>
          <div v-else class="newProduct-price">
            {{ data.text }}
          </div>
        </div>
      </div>
    </div>
    <van-loading v-if="isMore && !isEmpty" size="13px" class="loading">{{ $t("加载中") }}...</van-loading>
    <div v-if="isEmpty" class="more">{{ $t("沒有更多啦") }}~</div>
    <specificationDialog v-if="isSpecificationDialog1" @handleConfirm="handleConfirm" :isAuth="isAuth"
      @handleClose="handlespecificationClose" :show="isSpecificationDialog" :tagtype="submitGoodsList.tagtype"
      :searchProductId="detailId"></specificationDialog>
      <promptDialog ref="promptDialogRef" :confirmText="confirmText" :content="message" @success="handleGoauthentication" />

  </div>
</template>

<script>
import { getNewProductList, getSeachProductList } from "@/api/newProduct"
import { getProduct, getProductcreateTempOrder } from "@/api/productdetails.js"
import Mixins from "@/utils/mixins"
import Config from "@/utils/config.js"
import i18n from "@/i18n"
import baseURL from "@/utils/config"
import promptDialog from "@/components/promptDialog/index.vue"

export default {
  mixins: [Mixins],
  components: {
    promptDialog
  },
  data () {
    return {
      data: {},
      formPage: {
        pageIndex: 1,
        pageSize: 10,
        isInit: true,
        tagID: "8"
      },
      productPage: {
        pageIndex: 1,
        title: "",
        searchType: "",
        brandId: "",
        pageSize: 10
      },
      isMore: false,
      finished: false,
      imgUrl: baseURL.imgUrl,
      isEmpty: false,
      defaultImg: require('@/assets/image/e-Rith.png'),
      detailId: null,
      submitGoodsList: {
        goods: [],
        isFromShoppingCart: false,
        isPreOrder: false,
      },
      isSpecificationDialog1: false,
      isSpecificationDialog: false,
      isAuth: {},
      message:'',
      confirmText: this.$t('去認證'),
    }
  },
  async created () {
    if (this.$route.query.langEnv == 2) {
      i18n.global.locale.value = "en"
    } else if (this.$route.query.langEnv == 3) {
      i18n.global.locale.value = "zh"
    }
    let id = this.$route.params.tagID
    this.formPage.tagID = id
    // switch (id) {
    //   case "6":
    //     document.title = this.$t("特價商品");
    //     break;
    //   case "7":
    //     document.title = this.$t("熱賣商品");
    //     break;
    //   case "8":
    //     document.title = this.$t("新品專區");
    //     break;
    //   case "12":
    //     document.title = this.$t("近效期折扣");
    //     break;
    // }
    window.addEventListener("scroll", this.throttle(this.handleScroll, 1000))

    await this.getList()
    if (this.$route.params.brandId) {
      document.title = this.data?.products[0]?.brandName || ''

    } else if (this.$route.params.categoryId) {
      document.title = this.data?.parentCategories?.find(item => item.id === this.$route.params.categoryId)?.name || ''
    }
    this.isSpecificationDialog1 = true
  },
  methods: {
    handleGoauthentication(){
      this.$router.push('/infoPage')
    },
    // 立即購買
    async handleBuyProduct (row) {
      this.detailId = row.id
      const res = await getProduct({ id: row.id })
      if (res.resultID == 1200) {
        this.list = res.data
        if (this.list.page == null) {
          this.list.page = ""
        }
        this.list.page = this.appendDomainToImgSrc(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.list.page = this.replaceLinks(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.isAuth = { isAuth: this.list.isAuth, text: this.list.text,btnType:2 }

        this.isSpecificationDialog = true
      }

    },
    // 下单
    async handleConfirm (data) {
      var _this = this
      this.submitGoodsList.goods = data.map(item => {
        return {
          searchProductId: item.searchProductId,
          id: item.searchProductId,
          count: item.amount,
          tagType: ''
        }
      })
      var func1 = function () {
        mmm.placeOrderList(JSON.stringify(_this.submitGoodsList.goods))
      }
      var func2 = function () {
        window.webkit.messageHandlers.placeOrderList.postMessage({ goods: JSON.stringify(_this.submitGoodsList.goods) })
      }
      var func3 = async function () {
        const res = await getProductcreateTempOrder(_this.submitGoodsList)
        if (res.resultID === 1200) {
          _this.$router.push("/order/confirm2")
        }else if(res.resultID===1340){
          _this.$refs.promptDialogRef.open()
          _this.handlespecificationClose()
          _this.message=res.message
      }
      }

      this.excFunc(func1, func2, func3)

    },
    appendDomainToImgSrc (htmlString, domain) {
      // 正则表达式匹配<img>标签的src属性
      const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g

      // 回调函数，用于替换匹配到的src值
      function replaceSrc (match, src) {
        // 检查src是否以http或https开头
        if (src.startsWith('http://') || src.startsWith('https://')) {
          return match // 如果是，则返回原始字符串
        }

        // 拼接域名和原src路径
        const newSrc = domain + src
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc)
      }

      // 使用正则表达式替换所有匹配到的src值
      const resultHtml = htmlString?.replace(imgSrcPattern, replaceSrc)

      return resultHtml
    },
    replaceLinks (text, domain) {
      // 正则表达式匹配 href 属性中的路径
      const regex = /href="(\/upload\/files\/file[^"]*)"/g
      // 替换函数
      return text.replace(regex, (match, path) => {
        // 在路径前添加域名
        return match.replace(path, `${domain}${path}`)
      })
    },
    handlespecificationClose () {
      this.isSpecificationDialog = false
    },
    async handleScroll (event) {
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      // 判断是否触底
      const isBottomReached = () => {
        const bottomOffset = 20 // 距离底部多少像素时认为触底
        return (
          documentHeight - (scrollTop + windowHeight) <= bottomOffset &&
          hasVerticalScrollbar
        )
      }

      if (isBottomReached()) {
        this.isMore = true
        if (this.$route.params.brandId || this.$route.params.categoryId) {
          this.productPage.pageIndex += 1
        } else {
          this.formPage.pageIndex += 1
        }

        this.formPage.isInit = false
        await this.getList()
      }
    },
    async onLoad () {
      this.formPage.pageIndex += 1
      await this.getList()
    },
    getNewProductDetial (item) {

      var _this = this
      var func1 = function () {
        mmm.goToDetail(item.id)
      }
      var func2 = function () {
        window.webkit.messageHandlers.goToDetail.postMessage(item.id)
      }
      var func3 = function () {
        _this.$router.push(`/product/${item.id}`)
      }
      this.excFunc(func1, func2, func3)
    },
    async getList () {
      if (this.isEmpty) {
        return
      }
      if (this.$route.params.brandId) {
        this.productPage.brandId = this.$route.params.brandId
        this.productPage.searchType = "brandId"
        this.productPage.categoryId = undefined
      } else if (this.$route.params.categoryId) {
        this.productPage.categoryId = this.$route.params.categoryId
        this.productPage.searchType = "categoryId"
        this.productPage.brandId = undefined
      }
      const res =
        this.$route.params.brandId || this.$route.params.categoryId
          ? await getSeachProductList(
            this.productPage,
            this.$route.query.langEnv
          )
          : await getNewProductList(this.formPage, this.$route.query.langEnv)
      if (this.formPage.pageIndex > 1 || this.productPage.pageIndex > 1) {
        if (res.data.products && res.data.products?.length === 0) {
          this.isMore = false
        } else {
          if (res.data?.products) {
            this.data.products = [...this.data.products, ...res.data?.products]
          }
        }
      } else {
        this.data = res.data
      }
      if (res.data?.products?.length === 0) {
        this.isMore = false
        this.isEmpty = true
      }
    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
  },
  beforeUnmount () {
    this.isSpecificationDialog1 = false
  }
}
</script>

<style lang="scss">
.newProduct-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;

  .newProduct-list-item {
    width: 49.596%;
    background-color: #fff;
    padding: 2px;
    border-radius: 12px;
    border-bottom: 3px solid #f3f4f8;
    overflow: hidden;
    position: relative;

    .tag {
      width: 42px;
      height: 42px;
      background: url("~@/assets/image/discounts-tag.png") 100% 100% no-repeat;
      background-size: cover;
      position: absolute;
      left: 4px;
      top: 4px;

      .top {
        font-family: OPlusSans 3.0, OPlusSans 30;
        font-weight: bold;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 24px;
        text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
        text-align: center;

        span {
          font-size: 17px;

        }
      }

      .bottom {
        margin-top: -6px;
        font-family: OPlusSans 3.0, OPlusSans 30;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 16px;
        text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
        text-align: center;
      }
    }

    .newProduct-list-img {
      img {
        object-fit: contain;
        min-height: 180px;
      }
    }


    .newProduct-info {
      padding: 8px;

      .newProduct-name {
        font-weight: 500;
        min-height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置为2行 */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .newProduct-Originalprice {
        text-decoration: line-through;
        font-size: 12px;
        color: #999;
      }

      .newProduct-price {
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 16px;
        color: #f82207;
        // line-height: 32px;
      }

      .add {
        width: 24px;
        height: 24px;
        font-size: 0;

        img {
          width: 100%;
          height: 100%  }
      }
    }
  }
}

.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}

.loading {
  text-align: center;
  padding-bottom: 10px;
}
</style>
